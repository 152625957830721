import React from "react";
import GatsbyImages from "../../GatsbyImages";
import "./fiveReasons.css";
const FiveReasons = ({ data }) => {
  if (!data) return null;
  const list = data.ListReasons;

  return (
    <div className="info-block fivereasons">
      <div className="container">
        <div className="info-block-in">
          <div className="img-block d-flex flex-wrap">
            <div className="block-rgt col-xl-8 col-lg-6 col-12">
              <h2>{data.title}</h2>
            </div>
            <div className="man-picture col-xl-4 col-lg-4 col-12">
              <GatsbyImages
                src={data.image.localFile.childImageSharp}
                ext={data.image.ext}
                imgClassName="block2-img"
                alt={data.image.alternativeText || "dont miss discount image"}
                layout={"constrained"}
                objectFit={"contain"}
              />
            </div>
            <div className="clear"></div>
          </div>
          <div className="reasonslist">
            <div className="reasonlistinner">
              <ul className="autoplay2">
                {data.ListReasons.map(({ title, textFormat, text }, key) => {
                  return (
                    <li key={key}>
                      <div className="lft-one">{title}</div>
                      <div
                        className="rgt-one"
                        dangerouslySetInnerHTML={{
                          __html: textFormat !== "" ? textFormat : text,
                        }}
                      ></div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="slider-nav">
              {list.map((value, key) => {
                return <span key={key}>{key + 1}</span>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { FiveReasons };
