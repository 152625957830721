import React from "react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import GatsbyImages from "../../GatsbyImages";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "./availableInAnyMoment.css";
const AvailableInAnyMoment = ({ data }) => {
  const videoUrl = data?.urlVideo;

  let videoId;
  // let videoHeight;
  // let videoWidth;
  if (videoUrl) {
    // Extracting width and height using regular expressions
    // const widthRegex = /width="(\d+)"/;
    // const heightRegex = /height="(\d+)"/;
    // const widthMatch = videoUrl.match(widthRegex);
    // const heightMatch = videoUrl.match(heightRegex);
    // videoWidth = widthMatch ? widthMatch[1] : "";
    // videoHeight = heightMatch ? heightMatch[1] : "";

    // Extracting video ID using regular expressions
    const videoIdRegex = /\/embed\/([a-zA-Z0-9_-]{11})/;
    const videoIdMatch = videoUrl.match(videoIdRegex);
    videoId = videoIdMatch ? videoIdMatch[1] : "";
  }

  const multimediaComponent = () => {
    if (data.urlVideo === "" || data.urlVideo === null) {
      return (
        <GatsbyImages
          loading="lazy"
          src={data.image.localFile.childImageSharp.gatsbyImageData}
          alt={data.image.alternativeText}
          height={434}
          className="mobile-envio"
        />
      );
    }
    if (data.urlVideo !== null && data.urlVideo !== "") {
      return (
        <LiteYouTubeEmbed
          id={videoId}
          title="video_player"
          noCookie={true}
          webp={true}
        />
      );
    }
  };

  return (
    <div className="mobile mobile-send mt-4">
      <div className="container">
        <div className="mobile-in mobile-video d-flex flex-wrap">
          <div className="mobile-rgt col-lg-6 col-md-12">
            <h2
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            ></h2>
            <p
              dangerouslySetInnerHTML={{
                __html: data.text,
              }}
            ></p>
            <div className="store d-flex flex-wrap">
              {data.link.map((val, index) => {
                return (
                  <a
                    href={val.path}
                    key={index}
                    target={val.target}
                    className="enviar_button"
                  >
                    <i className={val.classes} aria-hidden="true"></i>
                    {val.name}
                  </a>
                );
              })}
            </div>
          </div>
          <div className="mobile-lft col-lg-6 col-md-12">
            <figure>{multimediaComponent()}</figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AvailableInAnyMoment };
