import React, { useState } from "react";
import GatsbyImages from "../../GatsbyImages";
const HowItWorks = ({ data }) => {
  const [active, setActive] = useState();
  const addActiveClass = (e) => {
    const clicked = e.target.id;
    setActive(active === clicked ? "" : clicked);
  };

  return (
    <div className="options multiple-opt-second mb-4">
      <div className="container">
        <div className="options-in">
          <h2
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          ></h2>
          <p className="h3">{data.subtitle}</p>
          <div className="d-flex flex-wrap">
            <div className="girl col-xl-5 col-lg-4 col-12">
              <GatsbyImages
                src={data.image.localFile.childImageSharp.gatsbyImageData}
                alt={data.image.alternativeText}
                width={482}
                height={653}
                ext={data.image.ext}
              />
            </div>
            <div className="list-opt-main col-xl-7 col-lg-8 col-12">
              <div className="list-opt d-flex">
                <div className="list_faqs_list">
                  <div className="faqs_list_into">
                    {data.ListTitleText.map((val, index) => {
                      return (
                        <div className="cat_list" key={index}>
                          <div
                            id={`${index}`}
                            onClick={addActiveClass}
                            role="button"
                            tabIndex={0}
                          >
                            <span
                              id={`${index}`}
                              onClick={addActiveClass}
                              role="button"
                              tabIndex={0}
                            >
                              {val.title}
                            </span>
                            <i
                              className={active === `${index}` ? "open" : ""}
                              id={`${index}`}
                              role="button"
                              tabIndex={0}
                            ></i>
                          </div>
                          <div
                            className={active === `${index}` ? "open" : ""}
                            dangerouslySetInnerHTML={{
                              __html:
                                val.textFormat !== ""
                                  ? val.textFormat
                                  : val.text,
                            }}
                          ></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="clear"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { HowItWorks };
