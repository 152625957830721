import React, { useState } from "react";

const WhySendity = ({ data }) => {
  const [active, setActive] = useState("0");

  const addActiveClass = (e) => {
    const clicked = e.target.id;

    setActive(clicked);
  };

  if (!data) return null;

  return (
    <div className="whysendity options multiple-opt-second mb-4">
      <div className="container">
        <div className="options-in">
          <h2
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          ></h2>

          <div className="allquestions d-flex flex-wrap">
            <div className=" col-xl-5 col-lg-4 col-12">
              <ul className="question">
                {data.WhySendityList.map((item, key) => {
                  return (
                    <li
                      key={key}
                      id={`${key}`}
                      onClick={addActiveClass}
                      className={active === `${key}` ? "open" : ""}
                      role="button"
                      tabIndex={0}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="list-opt-main col-xl-7 col-lg-8 col-12">
              <ul className="answer">
                {data.WhySendityList.map((item, key) => {
                  return (
                    <li
                      className={active === `${key}` ? "open" : ""}
                      key={key}
                      dangerouslySetInnerHTML={{
                        __html: item.text,
                      }}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { WhySendity };
