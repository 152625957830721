import React from "react";
import GatsbyImages from "../../GatsbyImages";
import { Link } from "gatsby";

const Landings = ({ data, lang }) => {
  // const [count, setCount] = useState(0);
  //solo se usa en enviar-dinero-{country}

  return (
    // <div className={count === 0 ? "landings" : "landings clickokviewmore"}>
    <div className="landings">
      <div className="container">
        <h2>{data.title}</h2>

        <ul className="clearfix  flex-wrap">
          {data.country.map(({ url, flag, title }, index) => {
            return (
              <li key={index}>
                <Link
                  to={lang === "es" ? url : `/en${url}`}
                  className="mapLink"
                >
                  <GatsbyImages
                    src={flag.localFile.childImageSharp.gatsbyImageData}
                    alt={flag.alternativeText}
                    ext={flag.ext}
                    height={14}
                    width={24}
                  />
                  <span>{title}</span>
                </Link>
              </li>
            );
          })}
        </ul>
        {/* <div
          className="landings-btn"
          onClick={() => setCount(count + 1)}
          role="button"
          tabIndex={0}
        >
          <span>
            {lang === "es" ? "Ver más" : `View more`}{" "}
            <img
              loading="lazy"
              src={arrow}
              alt="arrow"
              height={16}
              width={12}
            />
          </span>
        </div> */}
      </div>
    </div>
  );
};

export { Landings };
